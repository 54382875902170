import React, { Component } from 'react';
import ReactDOM from 'react-dom';

export interface DotProps {
  isActive?: boolean;
  tag: string;
  page: number;
}

interface State {
    isActive: boolean;
    wasActive: boolean;
    hover: boolean;
}

/**
  A clickable dot which retains active/inactive state depending on whether
  the current page matches the current dot. Required attributes are tag and
  page, which are the element id and the page number, respectively.
*/
export default class Dot extends Component<DotProps, State> {

  constructor(props: Readonly<DotProps>) {
    super(props);
    this.state = {
      isActive: props.isActive || false,
      wasActive: false,
      hover: false,
    }
    window.addEventListener('scroll', this.handleScroll, true);
  }

  componentDidMount() {
    const el = ReactDOM.findDOMNode(this) as Element;
    if (!el) { return; }
    el.addEventListener('mouseover', this.handleMouseover);
    el.addEventListener('mouseout', this.handleMouseout);
  }

  /**
    Scroll to the page that matches the current dot.
  */
  scrollTo = () => {
    const paged = document.getElementById('paged');
    if (paged) {
      paged.classList.remove('mandatory-scroll-snapping');
      paged.classList.add('mandatory-scroll-nosnap');
      setTimeout(() => {
        paged.classList.remove('mandatory-scroll-nosnap');
        paged.classList.add('mandatory-scroll-snapping');
      }, 750);
    }
    const el = document.getElementById(this.props.tag);
    if (!el) { return; }
    el.scrollIntoView({ behavior: 'smooth', block: 'end'});
  }

  /**
    Update state to active/inactive depending on whether or not the current
    page matches the current dot.
  */
  handleScroll = () => {
    const paged = document.getElementById('paged');
    if (!paged) { return; }
    const page = document.getElementById(this.props.tag);
    if (!page) { return; }
    // If offset is less than half of a page height from a page
    // then that page is the current page.
    const middle = window.innerHeight / 2.0;
    // @ts-ignore
    const isActive = Math.abs(paged.scrollTop - page.offsetTop) < middle;
    if (isActive === this.state.isActive) { return; }
    this.setState({
      isActive,
      wasActive: !isActive,
      hover: false,
    });
  };

  handleMouseover = () => {
    this.setState({
      isActive: this.state.isActive,
      wasActive: this.state.wasActive,
      hover: true,
    });
  }

  handleMouseout = () => {
    this.setState({
      isActive: this.state.isActive,
      wasActive: this.state.wasActive,
      hover: false,
    });
  }

  render() {
    let dotClass = (this.state.isActive || this.state.hover) ? 'pager-active' : 'pager-dot';
    if (this.state.hover) { dotClass += ' pager-hover'; }
    // Only set 'deactivate' state if the dot was previously active,
    // this prevents flicker from active to inactive on page-load.
    if (this.state.wasActive) { dotClass += ' deactivate'; }
    return (
      <button className='pager-button' onClick={this.scrollTo}>
        <div className={dotClass} />
      </button>
    );
  }
}
