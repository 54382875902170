import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { canAnimateSvg, isTouchDevice } from '../../capabilities';

type HoverImageType = 'in' | 'out';

export interface ServiceProps {
  icon: string;
  title: string;
  description: string;
  url: string;
}

export default class Service extends Component<ServiceProps> {

  componentDidMount() {
    const el = ReactDOM.findDOMNode(this) as Element;
    if (!el) { return; }
    const details = el.querySelector('h3') as Element;
    if (!details) { return; }
    details.addEventListener('mouseover', this.handleMouseover);
    details.addEventListener('mouseout', this.handleMouseout);
  }

  handleMouseover = () => {
    this.setHoverImage('in');
  }

  handleMouseout = () => {
    this.setHoverImage('out');
  }

  /**
    Set hover image in child img element to the details-hover image of given type.
  */
  setHoverImage(type: HoverImageType) {
    if (isTouchDevice()) { return; }
    let ext = 'svg';
    if (!canAnimateSvg()) {
      ext = 'gif';
    }
    const el = ReactDOM.findDOMNode(this) as Element;
    const img = el.querySelector('img.details') as HTMLImageElement;
    if (!img) { return; }
    // Set image source to image with given type,
    // use svg unless this is a browser which cannot animate svgs
    // append random argument to url to force restart of animation
    img.src = '/images/details-hover-' + type + '.' + ext + '?rnd=' + Math.random();
  }

  render() {
    return (
      <div className='service'>
        <img className='icon' alt='icon' src={this.props.icon} />
        <h4>{this.props.title}</h4>
        <p dangerouslySetInnerHTML={{__html: this.props.description}}></p>
      </div>
    );
    /* insert after <p>{this.props.description}</p> to add details
    <a className='details' href={this.props.url}>
      <h3 className='cta'>details</h3>
      <img className='details' src='/images/details.svg' alt='details' />
    </a>
    */
  }
}
