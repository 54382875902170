import React, { Component } from 'react';
import Dot from './Dot';

export interface PagerProps {
  pages: string[];
}

/**
  Fixed position pager, with one dot for each page given in pages attribute.
*/
export default class Pager extends Component<PagerProps> {

  render() {
    const items = []
    for (let index = 0 ; index < this.props.pages.length ; index += 1) {
      const value = this.props.pages[index];
      // Let's mix javascript and html, because that's super readable 🤮
      items.push(<Dot key={index} page={index} tag={value} isActive={index === 0} />)
    }
    return (
      <div className='pager'>
        {items}
      </div>
    );
  }
}
