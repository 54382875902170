/**
  Detect browsers who can animatate SVGs.

  Safari and IE/Edge have a hard time animating SVGs, so naively
  return false for user agents which match either of those.
*/
export function canAnimateSvg(): boolean {
  const ua = window.navigator.userAgent;
  // Match IE/Edge
  if (ua.indexOf('MSIE') > 0 || ua.indexOf('Trident') > 0 || ua.indexOf('Edge') > 0 ||
      // Match Safari (Chrome pretends to be Safari, but does not have 'Version')
      (ua.indexOf('Safari') > 0 && ua.indexOf('Version/') > 0)) {
    return false;
  } else {
    return true;
  }
}

/**
  Detect browser which have touch screens.

  Apparently, tests for things link existence of touch events and similar
  prove unreliable, so this becomes a test for known user agents which use
  touch screens.
*/
export function isTouchDevice(): boolean {
  const ua = navigator.userAgent.toLowerCase();
  const matches = (ua.match(/(iphone|ipod|ipad)/) ||
    ua.match(/(android)/)  ||
    ua.match(/(iemobile)/) ||
    ua.match(/iphone/i) ||
    ua.match(/ipad/i) ||
    ua.match(/ipod/i) ||
    ua.match(/blackberry/i) ||
    ua.match(/bada/i)) || [];
  return matches.length > 0;
};
