import React, { Component } from 'react';
import Paged from './Paged';
import Intro from './Intro';
import About from './About';
import Services from './Services';
import Contact from './Contact';
import Pager from './Pager';

// @ts-ignore
import smoothscroll from 'smoothscroll-polyfill';

export default class App extends Component {

  componentDidMount() {
    smoothscroll.polyfill();
    console.log('polyfill');
  }

  render() {
    const pages = ['intro', 'about', 'services', 'contact'];
    return (
      <div>
        <Paged pages={pages}>
          <Intro />
          <About />
          <Services />
          <Contact />
        </Paged>
        <Pager pages={pages}/>
      </div>
    )
  }
}
