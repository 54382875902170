import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { canAnimateSvg, isTouchDevice } from '../capabilities';

type HoverImageType = 'in' | 'out';

export interface ScrollProps {
  title: string;
  tag: string;
}

export default class ScrollDown extends Component<ScrollProps> {
  componentDidMount() {
    const el = ReactDOM.findDOMNode(this) as Element;
    if (!el) { return; }
    const details = el.querySelector('h3') as Element;
    if (!details) { return; }
    details.addEventListener('mouseover', this.handleMouseover);
    details.addEventListener('mouseout', this.handleMouseout);
    if (!canAnimateSvg()) {
      const img = el.querySelector('img');
      if (!img) { return; }
      img.src = '/images/scroll-anim.gif';
    }
  }

  scrollDown = () => {
    const paged = document.getElementById('paged');
    if (paged) {
      paged.classList.remove('mandatory-scroll-snapping');
      paged.classList.add('mandatory-scroll-nosnap');
      setTimeout(() => {
        paged.classList.remove('mandatory-scroll-nosnap');
        paged.classList.add('mandatory-scroll-snapping');
      }, 750);
    }
    const el = document.getElementById(this.props.tag);
    if (!el) { return; }
    el.scrollIntoView({ behavior: 'smooth', block: 'end'});
  }

  handleMouseover = () => {
    this.setHoverImage('in');
  }

  handleMouseout = () => {
    this.setHoverImage('out');
  }

  /**
    Set hover image in child img element to the scroll-hover image of given type.
  */
  setHoverImage(type: HoverImageType) {
    if (isTouchDevice()) { return; }
    let ext = 'svg';
    if (!canAnimateSvg()) {
      ext = 'gif';
    }
    const el = ReactDOM.findDOMNode(this) as Element;
    const img = el.querySelector('img');
    if (!img) { return; }
    // Set image source to image with given type,
    // use svg unless this is a browser which cannot animate svgs
    // append random argument to url to force restart of animation
    img.src = '/images/scroll-hover-' + type + '.' + ext + '?rnd=' + Math.random();
  }

  render() {
    return (
      <button className='scroll-down' onClick={this.scrollDown}>
        <img src='/images/scroll-anim.svg' alt={this.props.title} />
        <h3 className='cta'>{ this.props.title }</h3>
      </button>
    );
  }
}
