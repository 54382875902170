import React, { Component } from 'react';

export default class Contact extends Component {

  render() {
    return (
      <div id='contact' className='page contact'>
        <h1>C<span className='purple'>O</span>NTACT</h1>
        <p>
          For questions about our company or products and services.
        </p>
        <div className='column'>
          <h4>Contact</h4>
          <p>Call: <a className='purple' href='tel:+4550182053'>+45 50 18 20 53</a></p>
          <p>Email:&nbsp;<a className='purple' href='mailto:info@borch-andersen.com'>info@borch-andersen.com</a></p>
        </div>
        <div className='column'>
          <h4>Address</h4>
          <p>
            <a className='purple' target='_blank' rel='noopener noreferrer' href='https://www.google.com/maps/place/Frederiksborggade+15,+1360+København/@55.6837716,12.5687007'>
              Borch-Andersen ApS<br />
              Frederiksborggade 15, 3. sal<br />
              1360 København K<br />
              Denmark
            </a>
          </p>
        </div>
      </div>
    );
  }
}
