import React, { Component } from 'react';

export interface PagedProps {
  pages: string[];
}

/**
  Paged container, with snapping scroll to each individual page.
*/
export default class Paged extends Component<PagedProps> {
  private currentPage: number;

  constructor(props: Readonly<PagedProps>) {
    super(props);
    this.currentPage = 0;
    window.addEventListener('scroll', this.handleScroll, true);
    window.addEventListener('resize', this.handleResize, true);
  }

  /**
    Maintain current page when user scrolls.
  */
  handleScroll = () => {
    const paged = document.getElementById('paged');
    if (!paged) { return; }
    const middle = window.innerHeight / 2.0;

    // Iterate through pages and find the current page
    for (let page = 0 ; page < this.props.pages.length ; page += 1) {
      const el = document.getElementById(this.props.pages[page]);
      if (!el) { continue; }

      // If offset is less than half of a page height from a page
      // then that page is the current page.
      // @ts-ignore
      const isActive = Math.abs(paged.scrollTop - el.offsetTop) < middle;
      if (isActive) {
        this.currentPage = page;
        return;
      }
    }
  };

  /**
    Ensure that scroll is adjusted to stay on the current paged when window
    is resized.
  */
  handleResize = () => {
    const paged = document.getElementById('paged');
    if (!paged) { return; }
    const height = window.innerHeight;
    paged.scrollTo(0, height * this.currentPage);
  }

  render() {
    return (
      <div className='paged-holster'>
        <div id='paged' className='paged-container paged-y mandatory-scroll-snapping' dir='ltr'>
          { this.props.children }
        </div>
      </div>
    );
  }
}
