import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Service from './Service';
import ScrollDown from '../ScrollDown';

/**
  1 for right, -1 for left
 */
type Direction = 1 | -1;

export default class Services extends Component {

  left = () => {
    this.scroll(-1);
  }

  right = () => {
    this.scroll(1);
  }

  /**
    Scroll one service width in the given direction.
    If the service list was scrolled by less than a full service width then
    snap to the nearest lower service.
  */
  scroll(direction: Direction) {
    const el = ReactDOM.findDOMNode(this) as Element;
    if (!el) { return; }
    const list = el.querySelector('.service-list') as Element;
    if (!list) { return; }
    const width = list.children[0].clientWidth;
    let page = Math.floor(list.scrollLeft / width);
    page += direction;
    list.scrollTo({
      top: 0,
      left: width * page,
      behavior: 'smooth',
    });
  }

  render() {
    return (
      <div id='services' className='services page'>
        <h1>SEr<span className='purple'>V</span>iCES</h1>
        <p>We provide varied services for many kinds of business needs.</p>
        <button className='left' onClick={this.left}>
          <img src='/images/details.svg' alt='left' />
        </button>
        <button className='right' onClick={this.right}>
          <img src='/images/details.svg' alt='right' />
        </button>
        <hr />
        <div className='service-list'>
          <Service
            icon='/images/coding.svg'
            title='Coding'
            description={
              'If your business needs require custom software, setup or ' +
              'specialised services then we can help with your specific needs.'
            }
            url='/coding'
          />
          <Service
            icon='/images/marketing.svg'
            title='Marketing'
            description={
              'We can help with marketing services, everything from helping ' +
              'with visual identity to recurring SoMe updates.'
            }
            url='/marketing'
          />
          <Service
            icon='/images/analytics.svg'
            title='Analytics'
            description={
              'We provide analytics and business intelligence solutions to ' +
              'help you succeed in the market specific to your business.'
            }
            url='/analytics'
          />
          <Service
            icon='/images/design.svg'
            title='Design'
            description={
              'Need custom design for your website, app, or banners for your ' +
              'event? We can help with that too.'
            }
            url='/design'
          />
          <Service
            icon='/images/hosting.svg'
            title='Hosting'
            description={
              'If you have special requirements to hosting your specific ' +
              'solution, or if you just want a turn-key website with no hassles, ' +
              'then we can help. 100% of the energy used to power our servers ' + 
              '<a class="purple" href="https://www.interxion.com/why-interxion/sustainability" target="_blank">is ' +
              'from renewable sources</a>.'
            }
            url='/hosting'
          />
        </div>
        <ScrollDown tag='contact' title='contact' />
      </div>
    );
  }
}
