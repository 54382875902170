import React, { Component } from 'react';
import ScrollDown from '../ScrollDown';

export default class Intro extends Component {

  scrollDown = () => {
    const el = document.getElementById('contact');
    if (!el) { return; }
    el.scrollIntoView({ behavior: 'smooth', block: 'end'});
  }

  render() {
    return (
      <div id='intro' className='intro'>
        <div className='vcentered'>
          <img alt='' className='hero' src='/images/bold-logo.png' />
          <h1>B<span className='purple'>O</span>rCH-ANDE<span className='purple'>R</span>SEN</h1>
          <h2>Professional services and marketing intelligence</h2>
          <p>
            We supply your business with customised professional
            solutions which fit the needs of your company, be that
            implementation of software to fit your processes,
            specific product needs, or marketing of existing products
            or services.
          </p>
          <ScrollDown tag='about' title='about' />
        </div>
        <button className='scroll-down contact-cta' onClick={this.scrollDown}>
          <h3 className='cta'>contact</h3>
        </button>
      </div>
    );
  }
}
