import React, { Component } from 'react';
import ScrollDown from '../ScrollDown';

export default class About extends Component {
  render() {
    return (
      <div id='about' className='page about'>
        <h1>AB<span className='purple'>O</span>uT</h1>
        <h2>We make things</h2>
        <p>
          As a team, we combine the strengths of marketing and social media wits
          with many years of engineering experience.
        </p>
        <p className='long'>
          We love beautiful designs, we passionately nurture every detail to get
          the best possible result in everything we do. We specialise in
          creating the best possible solutions to bring your product to market
          or maximizing the market penetration for your existing product or
          service.
        </p>
        <div className='profile'>
          <div className='container'>
            <div id='anders' className='portrait' />
            <div>
              <h3>Anders Borch</h3>
              <p>Engineer</p>
              <a target='_blank' href='https://linkedin.com/in/cyborch' rel='noopener noreferrer'>
                <img className='social' src='/images/linkedin.svg' alt='linkedin' />
              </a>
              <a target='_blank' href='https://blog.cyborch.com/' rel='noopener noreferrer'>
                <img className='social rss' src='/images/rss.svg' alt='blog' />
              </a>
            </div>
            <div id='gitte' className='portrait' />
            <div>
              <h3>Gitte Andersen</h3>
              <p>Marketer</p>
              <a target='_blank' href='https://linkedin.com/in/strittegitte' rel='noopener noreferrer'>
                <img className='social' src='/images/linkedin.svg' alt='linkedin' />
              </a>
              <a target='_blank' href='https://www.instagram.com/strittegitte/' rel='noopener noreferrer'>
                <img className='social' src='/images/instagram.png' alt='instagram' />
              </a>
            </div>
          </div>
        </div>
        <ScrollDown tag='services' title='services' />
      </div>
    );
  }
}
